.aboutMe {
  width: 100%;
  height: auto;
  position: relative;
  font-family: 'Quicksand';
}

.intro {
  display: flex;
  flex-direction: column;
}

.introName {
  font-size: 3.0rem;
}

.introLine {
  /* font-size: 1.8rem; */
  /* font-family: 'Satisfy'; */
  font-size: 1.5rem;
  font-family: 'Quicksand';
  color: rgba(148, 148, 149);
}

.about {
  padding-top: 1.5rem;
  /* font-size: 1.2rem; */
  font-size: 1.1rem;
  color: rgba(148, 148, 149);
}

.aboutLine {
  text-align: justify;
}

/* details */
.section {
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
  color: rgba(148, 148, 149);
  font-size: 1.2rem;
  -webkit-text-stroke-width: thin;
}

.sectionLine {
  cursor: pointer;
  padding-top: 1rem;
}

.sectionLine:hover {
  color: rgba(255, 255, 255);
}
/* .sectionLine:active {
  text-transform: uppercase;
} */


/* contact */
.contact {
  display: flex;
  flex-direction: row;
  padding-top: 3rem;
  padding-bottom: 3rem;
  font-size: 1.2rem;
}

.contactLine {
  padding-right: 1rem;
}

a {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
  color: rgba(255, 255, 255);
}

@media screen and (min-device-width: 900px) {
  .aboutMe {
    position: fixed;
    left: 0;
    /* width: 40%; */
    height: 100vh;
    /* for components */
    display: flex;
    flex-direction: column;
    width: 45%;
    padding: 2% 2% 1% 6%;
  }
}


