*,
:before,
:after {
  box-sizing: border-box;
}

/* html {
  filter:invert(1) hue-rotate(180deg);
} */

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: rgba(21, 21, 21);
  color: rgb(255,255,255);
}
