.App {
}

.container {
  display: flex;
  flex-direction: column;
  padding: 1.6rem;
}

@media screen and (min-device-width: 900px) {
  .container {
    display: flex;
    flex-direction: row;
  }
}
