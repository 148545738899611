.details {
  width: 100%;
  height: auto;
  position: relative;
  font-family: 'Quicksand';
}

/* eudcation */
#education {
  display: flex;
  flex-direction: column;
}
.educationline {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  background-color: rgba(32, 32, 34);
  border: 1px solid rgba(32, 32, 34);
  padding: 2rem;
}

.educationplace {
  font-size: 0.99rem;
  color: rgba(148, 148, 149);
}

.educationamename {
  font-size: 1.34rem;
  padding-top: 0.3rem;
}

.educationdesc {
  font-size: 1.1rem;
  padding-top: 0.3rem;
}

/* projects */
#projects {
  display: flex;
  flex-direction: column;
}
.projectLine {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  background-color: rgba(32, 32, 34);
  border: 1px solid rgba(32, 32, 34);
  padding: 2rem;
}

.projecttech {
  font-size: 0.99rem;
  color: rgba(148, 148, 149);
}

.projectname {
  font-size: 1.34rem;
  padding-top: 0.3rem;
}

.projectdesc {
  font-size: 1.1rem;
  padding-top: 0.3rem;
}

/* work */
#work {
  display: flex;
  flex-direction: column;
}

.workline {
  background-color: rgba(32, 32, 34);
  border: 1px solid rgba(32, 32, 34);
  margin-bottom: 1rem;
  padding: 2rem;
  color: rgba(148, 148, 149);
  font-size: 1.3rem;
}

/* skills */
#skills {
  display: flex;
  flex-direction: column;
}

.skillLine {
  background-color: rgba(32, 32, 34);
  border: 1px solid rgba(32, 32, 34);
  margin-bottom: 1rem;
  padding: 2rem;
  color: rgba(148, 148, 149);
  font-size: 1.3rem;
}

/* courses */
#courses {
  display: flex;
  flex-direction: column;
}

.courseLine {
  background-color: rgba(32, 32, 34);
  border: 1px solid rgba(32, 32, 34);
  margin-bottom: 1rem;
  padding: 2rem;
  font-size: 1.2rem;
}

.design {
  font-size: 0.85rem;
  text-align: end;
}

@media screen and (min-device-width: 900px) {
  .details {
    position: absolute;
    right: 0;
    /* width: 60%; */
    display: flex;
    flex-direction: column;
    font-family: 'Quicksand';
    width: 55%;
    padding: 2% 2% 1% 6%;
  }
}
